import React from "react";
import * as PrimitiveDialog from "@radix-ui/react-dialog";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { Slot } from "@radix-ui/react-slot";
import { AnimatePresence, motion } from "framer-motion";

const missingProvider = "Component must be used within <Dialog.Root>";

export const DialogContext = React.createContext({
  get isOpen() {
    throw new Error(missingProvider);
  },
  get headless() {
    throw new Error(missingProvider);
  },
  get dismissable() {
    throw new Error(missingProvider);
  },
  get size() {
    throw new Error(missingProvider);
  },
});

DialogContext.displayName = "DialogContext";

export const SIZE = {
  FULL: "full",
  XS: "xs",
};

export function Root({
  defaultOpen,
  open,
  headless = false,
  dismissable = true,
  size = SIZE.XS,
  children,
  onOpenChange,
  ...delegated
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(defaultOpen);
  const isControlled = open !== undefined;
  const isOpen = isControlled ? open : uncontrolledOpen;

  const handleOpenChange = (nextValue) => {
    if (!isControlled) {
      setUncontrolledOpen(nextValue);
    }
    onOpenChange?.(nextValue);
  };

  const providerValue = React.useMemo(
    () => ({
      isOpen,
      headless,
      dismissable,
      size,
    }),
    [isOpen, headless, dismissable, size],
  );

  return (
    <DialogContext.Provider value={providerValue}>
      <PrimitiveDialog.Root
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={handleOpenChange}
        {...delegated}
      >
        {children}
      </PrimitiveDialog.Root>
    </DialogContext.Provider>
  );
}

export const Panel = React.forwardRef(function Panel(
  { className, backdropProps = {}, motionProps, children, ...delegated },
  forwardedRef,
) {
  const { isOpen, headless, dismissable, size } =
    React.useContext(DialogContext);
  const {
    motionProps: backdropMotionProps,
    className: backdropClassName,
    ...delegatedBackdropProps
  } = backdropProps;

  const shouldForceMount = !headless || motionProps || backdropMotionProps;

  return (
    <AnimatePresence>
      {isOpen ? (
        <PrimitiveDialog.Portal forceMount={shouldForceMount}>
          <PrimitiveDialog.Overlay
            className={twMerge(
              !headless && "backdrop-blur bg-[rgba(0,0,0,0.5)]",
              backdropClassName,
              "fixed inset-0 z-[2147483646]", // This is a value that is bigger than one trust dialog.
            )}
            asChild={true}
            forceMount={shouldForceMount}
            {...delegatedBackdropProps}
          >
            <motion.div
              initial={headless ? undefined : "closed"}
              animate={headless ? undefined : "enter"}
              exit={headless ? undefined : "closed"}
              variants={
                headless
                  ? undefined
                  : {
                      closed: {
                        opacity: 0,
                        transition: {
                          duration: 0.2,
                          ease: "easeIn",
                        },
                      },
                      enter: {
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                          ease: "easeOut",
                        },
                      },
                    }
              }
              {...backdropMotionProps}
            />
          </PrimitiveDialog.Overlay>

          <div
            className={twMerge(
              "fixed inset-0 z-[2147483646]", // This is a value that is bigger than one trust dialog.
              !headless &&
                "py-8 px-3 flex items-end justify-center md:items-center",
              size === SIZE.FULL && "py-0 px-0",
            )}
          >
            <PrimitiveDialog.Content
              ref={forwardedRef}
              className={twMerge(
                !headless &&
                  "w-screen flex flex-col p-6 bg-white rounded-lg max-h-[100dvh] overflow-y-auto outline-none",
                size === SIZE.FULL && "h-[100dvh] rounded-none bg-transparent",
                size === SIZE.XS && "md:w-screen-xs",
                className,
              )}
              forceMount={shouldForceMount}
              asChild={true}
              onEscapeKeyDown={(event) => {
                if (!dismissable) {
                  event.preventDefault();
                }
              }}
              onPointerDownOutside={(event) => {
                if (!dismissable) {
                  event.preventDefault();
                }
              }}
              {...delegated}
            >
              <motion.div
                initial={headless ? undefined : "closed"}
                animate={headless ? undefined : "enter"}
                exit={headless ? undefined : "closed"}
                variants={
                  headless
                    ? undefined
                    : {
                        closed: {
                          opacity: 0,
                          translateY: "1rem", // Matches `translate-y-4` (4 * 0.25rem)
                          scale: 0.95,
                          transition: {
                            duration: 0.2,
                            ease: "easeIn",
                          },
                        },
                        enter: {
                          opacity: 1,
                          translateY: 0,
                          scale: 1,
                          transition: {
                            duration: 0.3,
                            ease: "easeOut",
                          },
                        },
                      }
                }
                {...motionProps}
              >
                {children}
              </motion.div>
            </PrimitiveDialog.Content>
          </div>
        </PrimitiveDialog.Portal>
      ) : null}
    </AnimatePresence>
  );
});

export const Title = React.forwardRef(function Title(
  { className, children, ...delegated },
  forwardedRef,
) {
  const { headless } = React.useContext(DialogContext);

  return (
    <PrimitiveDialog.Title
      {...delegated}
      className={twMerge(!headless && "gn-headline-md text-center", className)}
      ref={forwardedRef}
    >
      {children}
    </PrimitiveDialog.Title>
  );
});

export const Description = React.forwardRef(function Description(
  { className, children, ...delegated },
  forwardedRef,
) {
  const { headless } = React.useContext(DialogContext);

  return (
    <PrimitiveDialog.Description
      {...delegated}
      className={twMerge(!headless && "gn-text text-center", className)}
      ref={forwardedRef}
    >
      {children}
    </PrimitiveDialog.Description>
  );
});

const InternalTrigger = React.forwardRef(function InternalTrigger(
  // eslint-disable-next-line react/prop-types
  { children, onClick: onPress, ...delegated },
  // eslint-disable-next-line no-unused-vars
  forwardedRef, // Trigger does not need a ref, but it's passed by default.
) {
  return (
    <Slot ref={forwardedRef} {...delegated} onPress={onPress}>
      {children}
    </Slot>
  );
});

export function Trigger({ children, asChild = false, ...delegated }) {
  if (asChild) {
    return (
      <PrimitiveDialog.Trigger asChild={true} {...delegated}>
        <InternalTrigger>{children}</InternalTrigger>
      </PrimitiveDialog.Trigger>
    );
  }

  return (
    <PrimitiveDialog.Trigger {...delegated}>{children}</PrimitiveDialog.Trigger>
  );
}

const InternalCloseTrigger = React.forwardRef(function InternalClose(
  // eslint-disable-next-line react/prop-types
  { children, onClick: onPress, ...delegated },
  // eslint-disable-next-line no-unused-vars
  forwardedRef, // Close does not need a ref, but it's passed by default.
) {
  return (
    <Slot ref={forwardedRef} {...delegated} onPress={onPress}>
      {children}
    </Slot>
  );
});

export function CloseTrigger({ children, asChild = false, ...delegated }) {
  if (asChild) {
    return (
      <PrimitiveDialog.Close asChild={true} {...delegated}>
        <InternalCloseTrigger>{children}</InternalCloseTrigger>
      </PrimitiveDialog.Close>
    );
  }

  return (
    <PrimitiveDialog.Close {...delegated}>{children}</PrimitiveDialog.Close>
  );
}

Root.propTypes = {
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  headless: PropTypes.bool,
  dismissable: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.FULL, SIZE.XS]),
  children: PropTypes.node,
  onOpenChange: PropTypes.func,
};

Trigger.propTypes = {
  children: PropTypes.node,
  asChild: PropTypes.bool,
};

CloseTrigger.propTypes = {
  children: PropTypes.node,
  asChild: PropTypes.bool,
};

Panel.propTypes = {
  backdropProps: PropTypes.object,
  motionProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
};

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Description.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
