import React from "react";
import PropTypes from "prop-types";
import { useButton } from "react-aria";
import { useComposedRefs } from "@gonoodle/gn-universe-utils";

const Pressable = React.forwardRef(function Pressable(
  {
    children,
    disabled,
    className,
    elementType,
    href,
    target,
    rel,
    buttonType,
    ariaLabel,
    testId,
    onPress,
    ...delegated
  },
  forwardedRef,
) {
  const ref = React.useRef();
  const Wrraper = elementType;
  const { buttonProps } = useButton(
    {
      isDisabled: disabled,
      elementType,
      href,
      target,
      rel,
      type: buttonType,
      "aria-label": ariaLabel,
      onPress,
      ...delegated,
    },
    ref,
  );
  const composedRefs = useComposedRefs(ref, forwardedRef);
  return (
    <Wrraper
      className={`touch-none 
      ${buttonProps.disabled && "cursor-not-allowed"} ${className}`}
      {...buttonProps}
      ref={composedRefs}
      data-testid={testId}
    >
      {children}
    </Wrraper>
  );
});

Pressable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  elementType: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  buttonType: PropTypes.oneOf(["button", "submit"]),
  ariaLabel: PropTypes.string,
  testId: PropTypes.string,
  onPress: PropTypes.func,
};

Pressable.defaultProps = {
  children: null,
  disabled: false,
  className: undefined,
  elementType: "button",
  href: undefined,
  target: undefined,
  rel: undefined,
  buttonType: undefined,
  ariaLabel: undefined,
  testId: undefined,
  onPress: undefined,
};

export default Pressable;
