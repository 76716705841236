/* eslint-disable react/jsx-no-constructed-context-values */
import React from "react";
import PropTypes from "prop-types";
import {
  useCheckbox,
  useCheckboxGroup,
  useCheckboxGroupItem,
  useFocusRing,
  VisuallyHidden,
} from "react-aria";
import { useCheckboxGroupState, useToggleState } from "react-stately";
import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

import { useSlot } from "../../../hooks";

const missingCheckboxGroupProvider =
  "Component must be used within <CheckboxGroup.Root>";
const CheckboxGroupContext = React.createContext({
  get labelProps() {
    throw missingCheckboxGroupProvider;
  },
  get labelRef() {
    throw missingCheckboxGroupProvider;
  },
});
CheckboxGroupContext.displayName = "CheckboxGroupContext";

const missingCheckboxProvider = "Component must be used within <Checkbox.Root>";
const CheckboxContext = React.createContext({
  get state() {
    throw missingCheckboxProvider;
  },
  get variant() {
    throw missingCheckboxProvider;
  },
  get inputProps() {
    throw missingCheckboxProvider;
  },
});
CheckboxContext.displayName = "CheckboxContext";

export const CheckboxGroup = {};
export const variants = {
  classic: "classic",
  custom: "custom",
};

CheckboxGroup.Root = function CheckboxGroupRoot({
  isDisabled,
  isReadOnly,
  defaultValue,
  value,
  name,
  className,
  children,
  onChange,
}) {
  const [labelRef, label] = useSlot();
  const state = useCheckboxGroupState({
    isDisabled,
    isReadOnly,
    defaultValue,
    value,
    name,
    onChange,
  });
  const { groupProps, labelProps } = useCheckboxGroup(
    {
      isDisabled,
      isReadOnly,
      defaultValue,
      value,
      name,
      label,
      onChange,
    },
    state,
  );

  return (
    <div {...groupProps} className={className}>
      <CheckboxGroupContext.Provider value={{ state, labelRef, labelProps }}>
        {children}
      </CheckboxGroupContext.Provider>
    </div>
  );
};

CheckboxGroup.Label = function CheckboxGroupLabel({ children, className }) {
  const { labelRef, labelProps } = React.useContext(CheckboxGroupContext);
  return (
    <span ref={labelRef} {...labelProps} className={className}>
      {children}
    </span>
  );
};

export function Label({ children, className }) {
  const { state, inputProps, variant } = React.useContext(CheckboxContext);

  const labelVariants = {
    [variants.classic]: "text-sm text-gray-700",
  };

  return (
    <label
      htmlFor={inputProps.id}
      data-selected={state.isSelected}
      data-focused={state.isFocused}
      className={twMerge(labelVariants[variant], className)}
    >
      {children}
    </label>
  );
}

export function Description({ children, className }) {
  const { inputProps, variant } = React.useContext(CheckboxContext);
  const descriptionVariants = {
    [variants.classic]: "gn-text-sm",
  };

  return (
    <span
      id={inputProps["aria-describedby"]}
      className={twMerge(descriptionVariants[variant], className)}
    >
      {children}
    </span>
  );
}

export function Error({ children, className }) {
  const { inputProps, variant } = React.useContext(CheckboxContext);
  const errorVariants = {
    [variants.classic]: {
      container: "flex",
      icon: "h-input-icon text-danger",
      text: "gn-text-sm text-danger",
    },
  };

  return variant !== variants.custom ? (
    <div className={twMerge(variants[variant].container, className)}>
      <ExclamationCircleIcon
        className={errorVariants[variant].icon}
        aria-hidden="true"
      />
      <span
        id={inputProps["aria-errormessage"]}
        className={errorVariants[variant].text}
      >
        {children}
      </span>
    </div>
  ) : (
    children
  );
}

export function Root({
  isIndeterminate,
  defaultSelected,
  isSelected,
  isDisabled,
  isReadOnly,
  isRequired,
  isInvalid,
  autoFocus,
  value,
  name,
  validationBehavior,
  variant,
  className,
  children,
  onChange,
}) {
  const { state: groupState } = React.useContext(CheckboxGroupContext);
  const toggleState = useToggleState({ name, isSelected, value, onChange });

  const id = React.useId();
  const inputRef = React.useRef(null);
  const { isFocused, focusProps } = useFocusRing();

  const { inputProps, isSelected: _isSelected } = groupState
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useCheckboxGroupItem(
        {
          isIndeterminate,
          isReadOnly,
          isDisabled,
          isRequired,
          autoFocus,
          value,
          name,
          id,
          "aria-describedby": `${id}-description`,
          "aria-errormessage": `${id}-error`,
          onChange,
        },
        groupState,
        inputRef,
      )
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useCheckbox(
        {
          isIndeterminate,
          defaultSelected,
          isSelected,
          isDisabled,
          isReadOnly,
          isRequired,
          isInvalid,
          autoFocus,
          value,
          name,
          validationBehavior: validationBehavior ?? "native",
          id,
          "aria-describedby": `${id}-description`,
          "aria-errormessage": `${id}-error`,
          onChange,
        },
        toggleState,
        inputRef,
      );

  const rootVariants = {
    [variants.classic]: {
      container: "flex",
      input:
        "focus:ring focus:ring-purple focus:outline-none focus:border-none h-4 w-4 mx-2 my-1 text-purple border-gray-300 rounded",
      childrenWrapper: "flex flex-col w-full overflow-hidden",
      error: "border-none ring ring-danger focus:ring-danger",
    },
  };

  return (
    <div
      data-selected={isSelected || undefined}
      className={twMerge(
        variant !== variants.custom
          ? twMerge(rootVariants[variant].container, className)
          : className,
      )}
    >
      {variant === variants.custom ? (
        <VisuallyHidden elementType="span">
          <input {...inputProps} {...focusProps} ref={inputRef} />
        </VisuallyHidden>
      ) : (
        <input
          {...inputProps}
          {...focusProps}
          ref={inputRef}
          className={twMerge(
            rootVariants[variant].input,
            isInvalid && variants[variant].error,
          )}
        />
      )}

      <CheckboxContext.Provider
        value={{
          state: { isSelected: _isSelected, isFocused },
          inputProps,
          variant,
        }}
      >
        {variant !== variants.custom ? (
          <div className={rootVariants[variant].childrenWrapper}>
            {children}
          </div>
        ) : (
          children
        )}
      </CheckboxContext.Provider>
    </div>
  );
}

Root.propTypes = {
  isIndeterminate: PropTypes.bool,
  defaultSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  validationBehavior: PropTypes.oneOf(["aria", "native"]),
  variant: PropTypes.oneOf(["classic", "custom"]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
};

Root.defaultProps = {
  isIndeterminate: false,
  defaultSelected: false,
  isSelected: undefined,
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  isInvalid: false,
  autoFocus: false,
  value: undefined,
  name: undefined,
  validationBehavior: "aria",
  variant: variants.classic,
  className: undefined,
  children: undefined,
  onChange: undefined,
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Label.defaultProps = {
  children: undefined,
  className: undefined,
};

Description.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Description.defaultProps = {
  children: undefined,
  className: undefined,
};

Error.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Error.defaultProps = {
  children: undefined,
  className: undefined,
};

CheckboxGroup.Root.propTypes = {
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
};

CheckboxGroup.Root.defaultProps = {
  isDisabled: false,
  isReadOnly: false,
  defaultValue: undefined,
  value: undefined,
  name: undefined,
  className: undefined,
  children: undefined,
  onChange: undefined,
};

CheckboxGroup.Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CheckboxGroup.Label.defaultProps = {
  children: undefined,
  className: undefined,
};
