/* eslint-disable import/no-relative-packages */
import React from "react";
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { find } from "lodash";
import { useRouter } from "next/router";
import Script from "next/script";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { usePrevious } from "@gonoodle/gn-universe-utils";
import {
  AssetsConfigProvider,
  OverlayProvider,
  Toast,
} from "@gonoodle/gn-universe-ui";
import { Montserrat } from "next/font/google";
import localFont from "next/font/local";

import { AppConfigProvider } from "../contexts/appConfig";
import { AnalyticsProvider } from "../contexts/Analytics";
import * as OneTrust from "../contexts/OneTrust";
import UserProvider, { useUser } from "../contexts/user";
import { BeaconProvider } from "../contexts/Beacon";
import { PianoProvider } from "../contexts/Piano";
import { TransmogrifierProvider } from "../contexts/Transmogrifier";
import SplashScreen from "../components/SplashScreen";
import { USER_TYPES, ROUTE_PREFIX, URLS } from "../constants";
import BugsnagClient from "../utils/bugsnag";
import config from "../config";

import "../../../assets/styles/tailwind.css";
import "../../../assets/styles/vars.css";
import "../../../assets/styles/utils.css";

const ErrorBoundary = BugsnagClient.getPlugin("react").createErrorBoundary();
const segment = new AnalyticsBrowser();
const montserrat = Montserrat({
  subsets: ["latin"],
  variable: "--font-montserrat",
});
const degular = localFont({
  src: "../../../assets/fonts/degular.woff2",
  style: "normal",
  weight: "800",
  variable: "--font-degular",
});
const degularDisplay = localFont({
  src: "../../../assets/fonts/degular-display.woff2",
  style: "italic",
  weight: "800",
  variable: "--font-degular-display",
});

export default function GoNoodle({ Component, pageProps }) {
  const router = useRouter();
  const assetsConfig = {
    assetsUrl: URLS.GN_ASSETS_BASE_URL,
  };

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: true,
          },
        },
      }),
  );

  /**
   * Adding font-sans Tailwind util to the main element to ensure that the font is applied to the entire app as a default.
   * The values for the font-body class are defined in the tailwind.config.js file under the fontFamily.sans property.
   */
  React.useEffect(() => {
    // Adding fonts to the document body to ensure that they can be used inside portals.
    document.body.classList.add(
      montserrat.variable,
      degular.variable,
      degularDisplay.variable,
      "font-body",
    );
  }, []);

  let splashScreenChildren = (
    <Toast.Provider>
      <TransmogrifierProvider>
        <Component {...pageProps} />
      </TransmogrifierProvider>
    </Toast.Provider>
  );

  // We shouldn't show the Splash Screen inside of SuperNoodle
  if (!router.asPath.startsWith(`/${ROUTE_PREFIX.CURRICULUM}`)) {
    splashScreenChildren = <SplashScreen>{splashScreenChildren}</SplashScreen>;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.RECAPTCHA_ENTERPRISE_KEY}
      scriptProps={{
        async: true,
        useEnterprise: true,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <OneTrust.Provider>
            <AppConfigProvider>
              <UserProvider>
                <ErrorBoundary>
                  <PianoProvider>
                    <AnalyticsProvider>
                      <BootstrapClientAnalytics />
                      <OverlayProvider>
                        <BeaconProvider>
                          <AssetsConfigProvider value={assetsConfig}>
                            {splashScreenChildren}
                          </AssetsConfigProvider>
                        </BeaconProvider>
                      </OverlayProvider>
                    </AnalyticsProvider>
                    <Script
                      src="https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.2.22/angular.min.js"
                      onError={(event) => {
                        BugsnagClient.notify({
                          name: "Cloudflare script failed to load",
                          message: `${event.target.src} failed to load`,
                        });
                      }}
                      strategy="afterInteractive"
                    />
                  </PianoProvider>
                </ErrorBoundary>
              </UserProvider>
            </AppConfigProvider>
          </OneTrust.Provider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
}

const BootstrapClientAnalytics = () => {
  const router = useRouter();
  const { user, updateUser } = useUser();
  const performanceConsentStatus = OneTrust.useConsentStatus(
    OneTrust.PERFORMANCE_SDKS,
  );
  const prevPerformanceConsentStatus = usePrevious(performanceConsentStatus);

  React.useEffect(() => {
    if (performanceConsentStatus === OneTrust.CONSENT_GIVEN) {
      segment
        .load(
          {
            writeKey: process.env.NEXT_PUBLIC_ANALYTICS_API_KEY,
          },
          { initialPageview: true },
        )
        .catch((err) => BugsnagClient.notify(err));
    }
  }, [performanceConsentStatus]);

  React.useEffect(() => {
    if (
      prevPerformanceConsentStatus === OneTrust.CONSENT_GIVEN &&
      performanceConsentStatus === OneTrust.CONSENT_NOT_GIVEN
    ) {
      segment?.reset();
      window.location.reload();
    }
  }, [performanceConsentStatus, prevPerformanceConsentStatus]);

  React.useEffect(() => {
    const handleRouteChange = (_, { shallow }) => {
      if (shallow === false) {
        segment.page({
          referrer: "", // disable referrer tracking for privacy
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  React.useEffect(() => {
    if (user?.analyticsId && user?.userTypeId) {
      segment.ready(() => {
        segment.identify(user?.analyticsId, {
          user_type: find(USER_TYPES, { id: user.userTypeId })?.name,
        });
      });
    }
  }, [user?.analyticsId, user?.userTypeId]);

  React.useEffect(() => {
    if (
      user?.allowTracking === false &&
      performanceConsentStatus === OneTrust.CONSENT_GIVEN
    ) {
      updateUser({ allowTracking: true });
    } else if (
      user?.allowTracking === true &&
      performanceConsentStatus === OneTrust.CONSENT_NOT_GIVEN
    ) {
      updateUser({ allowTracking: false });
    }
  }, [user?.allowTracking, updateUser, performanceConsentStatus]);

  return null;
};
