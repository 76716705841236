import React from "react";
import * as PrimitivePopover from "@radix-ui/react-popover";
import { Slot } from "@radix-ui/react-slot";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

const missingProvider = "Component must be used within <Popover.Root>";

export const PopoverContext = React.createContext({
  get isOpen() {
    throw new Error(missingProvider);
  },
  get dismissable() {
    throw new Error(missingProvider);
  },
  get headless() {
    throw new Error(missingProvider);
  },
});

export function Root({
  defaultOpen,
  open,
  dismissable = true,
  headless = false,
  children,
  onOpenChange,
  ...delegated
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(defaultOpen);
  const isControlled = open !== undefined;
  const isOpen = isControlled ? open : uncontrolledOpen;

  const handleOpenChange = (nextValue) => {
    if (!isControlled) {
      setUncontrolledOpen(nextValue);
    }
    onOpenChange?.(nextValue);
  };

  const providerValue = React.useMemo(
    () => ({
      isOpen,
      dismissable,
      headless,
    }),
    [isOpen, dismissable, headless],
  );

  return (
    <PopoverContext.Provider value={providerValue}>
      <PrimitivePopover.Root
        defaultOpen={defaultOpen}
        open={open}
        dismissable={dismissable}
        onOpenChange={handleOpenChange}
        {...delegated}
      >
        {children}
      </PrimitivePopover.Root>
    </PopoverContext.Provider>
  );
}

export function Panel({
  children,
  className,
  motionProps,
  style,
  ...delegated
}) {
  const { isOpen, headless, dismissable } = React.useContext(PopoverContext);

  return (
    <AnimatePresence>
      {isOpen ? (
        <PrimitivePopover.Portal forceMount={!headless}>
          <PrimitivePopover.Content
            {...delegated}
            className={className}
            forceMount={!headless}
            asChild={true}
            onEscapeKeyDown={(event) => {
              if (!dismissable) {
                event.preventDefault();
              }
            }}
            onPointerDownOutside={(event) => {
              if (!dismissable) {
                event.preventDefault();
              }
            }}
          >
            <motion.div
              initial={headless ? undefined : "closed"}
              animate={headless ? undefined : "enter"}
              exit={headless ? undefined : "closed"}
              variants={
                headless
                  ? undefined
                  : {
                      closed: {
                        opacity: 0,
                        scale: 0.95,
                        transition: {
                          duration: 0.2,
                          ease: "easeIn",
                        },
                      },
                      enter: {
                        opacity: 1,
                        scale: 1,
                        transition: {
                          duration: 0.3,
                          ease: "easeOut",
                        },
                      },
                    }
              }
              style={{
                ...style,
                transformOrigin:
                  "var(--radix-popover-content-transform-origin)",
              }}
              {...motionProps}
            >
              {children}
            </motion.div>
          </PrimitivePopover.Content>
        </PrimitivePopover.Portal>
      ) : null}
    </AnimatePresence>
  );
}

const InternalTrigger = React.forwardRef(function InternalTrigger(
  // eslint-disable-next-line react/prop-types
  { children, onClick: onPress, ...delegated },
  // eslint-disable-next-line no-unused-vars
  forwardedRef, // Trigger does not need a ref, but it's passed by default.
) {
  return (
    <Slot ref={forwardedRef} {...delegated} onPress={onPress}>
      {children}
    </Slot>
  );
});

export function Trigger({ children, asChild = false, ...delegated }) {
  if (asChild) {
    return (
      <PrimitivePopover.Trigger asChild={true} {...delegated}>
        <InternalTrigger>{children}</InternalTrigger>
      </PrimitivePopover.Trigger>
    );
  }

  return (
    <PrimitivePopover.Trigger {...delegated}>
      {children}
    </PrimitivePopover.Trigger>
  );
}

const InternalCloseTrigger = React.forwardRef(function InternalClose(
  // eslint-disable-next-line react/prop-types
  { children, onClick: onPress, ...delegated },
  // eslint-disable-next-line no-unused-vars
  forwardedRef, // Close does not need a ref, but it's passed by default.
) {
  return (
    <Slot ref={forwardedRef} {...delegated} onPress={onPress}>
      {children}
    </Slot>
  );
});

export function CloseTrigger({ children, asChild = false, ...delegated }) {
  if (asChild) {
    return (
      <PrimitivePopover.Close asChild={true} {...delegated}>
        <InternalCloseTrigger>{children}</InternalCloseTrigger>
      </PrimitivePopover.Close>
    );
  }

  return (
    <PrimitivePopover.Close {...delegated}>{children}</PrimitivePopover.Close>
  );
}

const { Arrow, Anchor } = PrimitivePopover;

export { Arrow, Anchor };

Root.propTypes = {
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  dismissable: PropTypes.bool,
  headless: PropTypes.bool,
  children: PropTypes.node,
  onOpenChange: PropTypes.func,
};

Trigger.propTypes = {
  asChild: PropTypes.bool,
  children: PropTypes.node,
};

CloseTrigger.propTypes = {
  asChild: PropTypes.bool,
  children: PropTypes.node,
};

Panel.propTypes = {
  motionProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};
