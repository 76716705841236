// COMPONENTS
import * as PrimitiveCheckbox from "./components/Checkbox";
import * as Select from "./components/Select";
import * as Dialog from "./components/Dialog";
import * as Popover from "./components/Popover";

// eslint-disable-next-line import/prefer-default-export
export const Checkbox = {
  ...PrimitiveCheckbox,
  CheckboxGroup: undefined,
};

export const CheckboxGroup = {
  Root: PrimitiveCheckbox.CheckboxGroup.Root,
  Label: PrimitiveCheckbox.CheckboxGroup.Label,
};

export { Select, Dialog, Popover };
