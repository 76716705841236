import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";
import { useRouter } from "next/router";

import BugsnagClient from "../../utils/bugsnag";
import { useUser } from "../user";

import { USER_TYPES } from "../../constants";

const missingPianoProvider = "Component must be used within <PianoProvider>";

const PianoContext = React.createContext({
  get piano() {
    throw new Error(missingPianoProvider);
  },
});

PianoContext.displayName = "PianoContext";

const events = {
  PAUSE_VIDEO_PLAYER: "PAUSE_VIDEO_PLAYER",
};

export function PianoProvider({ children }) {
  const { user } = useUser();
  const router = useRouter();
  const [isExperienceInitialized, setIsExperienceInitialized] = React.useState(
    false,
  );
  const eventCallbacks = React.useRef({});

  const piano = typeof window !== "undefined" && window.tp;

  const addEventListener = (eventType, handler) => {
    if (!eventCallbacks.current[eventType]) {
      eventCallbacks.current[eventType] = [];
    }
    eventCallbacks.current[eventType].push(handler);

    return () => {
      eventCallbacks.current[eventType] = eventCallbacks.current[
        eventType
      ].filter((callback) => callback !== handler);
    };
  };

  React.useLayoutEffect(() => {
    // Allows buffering of commands before Piano script loads
    window.tp = window.tp || [];
  }, []);

  React.useEffect(() => {
    window.tp.push([
      "setComposerHost",
      process.env.NEXT_PUBLIC_PIANO_COMPOSER_HOST,
    ]);
    window.tp.push(["setPianoIdUrl", process.env.NEXT_PUBLIC_PIANO_ID_URL]);
    window.tp.push(["setEndpoint", process.env.NEXT_PUBLIC_PIANO_ENDPOINT]);
    window.tp.push([
      "setStaticDomain",
      process.env.NEXT_PUBLIC_PIANO_CDN_DOMAIN,
    ]);
  }, []);

  React.useEffect(() => {
    if (!user.pianoAuthToken) {
      window.tp.push(["setExternalJWT", undefined]);
    }
  }, [user.pianoAuthToken]);

  React.useEffect(() => {
    const currentUserType = Object.values(USER_TYPES).find(
      (userType) => userType.id === user.userTypeId,
    );

    if (user.features.pianoReceiveUserData) {
      window.tp.push(["setCustomVariable", "userType", currentUserType?.name]);
    }
  }, [user.userTypeId, user.features.pianoReceiveUserData]);

  /**
   * Initialize Piano experience and set external JWT token.
   * This effect ignores the user.pianoAuthToken and user.features.pianoReceiveUserData dependency, as syncing the token
   * runs on page navigation. This will cause tabs to include stale token data, but that is acceptable from the product team.
   * If issues arise, from the fact that server state is not in sync with client state, this effect should be updated.
   */
  React.useEffect(() => {
    if (user.features.pianoReceiveUserData) {
      window.tp.push(["setExternalJWT", user.pianoAuthToken]);
    }

    window.tp.push([
      "init",
      () => {
        window.tp.experience.init();
        setIsExperienceInitialized(true);
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleRouteChange = () => {
      if (isExperienceInitialized) {
        if (user.features.pianoReceiveUserData) {
          window.tp.push(["setExternalJWT", user.pianoAuthToken]);
        }
        window.tp.experience.execute();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [
    router.events,
    isExperienceInitialized,
    user.pianoAuthToken,
    user.features.pianoReceiveUserData,
  ]);

  React.useEffect(() => {
    const handlePianoEvent = ({ detail }) => {
      const listeners = eventCallbacks.current[detail.type];

      if (listeners && listeners.length) {
        listeners.forEach((callback) => {
          if (typeof callback === "function") {
            callback(detail);
          }
        });
      }
    };

    window.addEventListener("pianoExperienceEvent", handlePianoEvent);

    return () => {
      window.removeEventListener("pianoExperienceEvent", handlePianoEvent);
    };
  }, []);

  return (
    <PianoContext.Provider value={{ piano, addEventListener }}>
      {user.features.piano ? (
        <Script
          src={`${process.env.NEXT_PUBLIC_PIANO_SCRIPT_URL}?aid=${process.env.NEXT_PUBLIC_PIANO_APPLICATION_ID}`}
          onError={(event) => {
            BugsnagClient.notify({
              name: "Piano script failed to load",
              message: `${event.target.src} failed to load`,
            });
          }}
          strategy="afterInteractive"
        />
      ) : null}
      {children}
    </PianoContext.Provider>
  );
}

export function usePiano({ handlePauseVideoPlayer = () => {} } = {}) {
  const { addEventListener } = React.useContext(PianoContext);

  React.useEffect(() => {
    const removePauseListener = addEventListener(
      events.PAUSE_VIDEO_PLAYER,
      handlePauseVideoPlayer,
    );

    return () => {
      removePauseListener();
    };
  }, [handlePauseVideoPlayer, addEventListener]);

  return {};
}

PianoProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

PianoProvider.defaultProps = {
  children: null,
};
