import React from "react";
import PropTypes from "prop-types";

import AgeGateBox from "../AgeGateBox";
import { Dialog } from "../../next";
import VisuallyHidden from "../VisuallyHidden";

export default function AgeGatedPressable({
  children,
  disabled,
  className,
  onPress,
  onAgeGateFailed,
}) {
  const [showAgeGate, setShowAgeGate] = React.useState(false);
  const [locked, setLocked] = React.useState(false);

  const onAgeGatePassed = async () => {
    await onPress();
    setShowAgeGate(false);
  };

  return (
    <Dialog.Root
      open={showAgeGate}
      dismissable={false}
      onOpenChange={(isOpen) => {
        setShowAgeGate(isOpen);
      }}
    >
      <Dialog.Trigger disabled={disabled} className={className}>
        {children}
      </Dialog.Trigger>

      <VisuallyHidden asChild={true}>
        <Dialog.Title>
          {locked ? "Sorry, buddy!" : "Are you a grown up?"}
        </Dialog.Title>
      </VisuallyHidden>

      <VisuallyHidden asChild={true}>
        <Dialog.Description>
          {locked
            ? "You need a grown up to help you"
            : "If not, get one to help you access this section. It's for grown ups only!"}
        </Dialog.Description>
      </VisuallyHidden>

      <Dialog.Panel>
        <AgeGateBox
          title={locked ? "Sorry, buddy!" : "Are you a grown up?"}
          description={
            locked
              ? "You need a grown up to help you."
              : "If not, get one to help you access this section. It's for grown ups only!"
          }
          locked={locked}
          autoFocus={true}
          onSuccess={onAgeGatePassed}
          onCancel={() => setShowAgeGate(false)}
          onLocked={() => {
            setLocked(true);
            onAgeGateFailed();
          }}
        />
      </Dialog.Panel>
    </Dialog.Root>
  );
}

AgeGatedPressable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onPress: PropTypes.func,
  onAgeGateFailed: PropTypes.func,
};

AgeGatedPressable.defaultProps = {
  disabled: false,
  className: undefined,
  onPress: () => {},
  onAgeGateFailed: () => {},
};
